//src/api/recomapi.js
import request from '@/utils/request'

/**
 * POST 方法 与 GET方法  区别
 *
 * post==> data: obj
 * get==>params: obj
 *
 */
//获取推荐商品列表接口
export function getrecomData(obj,url) {
    return request({
        url: '/api/admin/product/recommend',      // url = base url + request url
        method: 'get',
        params: obj,
        //----------------->>>区别
    })
}
//新增推荐商品接口
export function postaddrecom(obj,url) {
    return request({
        url: '/api/admin/product/recommend/add',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//编辑推荐商品接口
export function posteditrecom(obj,url) {
    return request({
        url: '/api/admin/product/recommend/edit',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//删除推荐商品接口
export function postdelrecom(obj,url) {
    return request({
        url: '/api/admin/product/recommend/del',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//获取产品绑定列表接口
export function getfrontData(obj,url) {
    return request({
        url: '/api/admin/front-product',      // url = base url + request url
        method: 'get',
        params: obj,
        //----------------->>>区别
    })
}
//新增产品绑定接口
export function postaddfront(obj,url) {
    return request({
        url: '/api/admin/front-product/add',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//编辑产品绑定接口
export function posteditfront(obj,url) {
    return request({
        url: '/api/admin/front-product/edit',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//删除产品绑定接口
export function postdelfront(obj,url) {
    return request({
        url: '/api/admin/front-product/del',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}

