<template>
  <div class="about">
    <div>
      <el-input style="width: 300px;margin: 0 10px 0 0"  v-model="keywords"></el-input>
      <el-button @click="search()" type="primary" style="margin:10px 0;" icon="el-icon-search"></el-button>
    </div>
    <el-table  height="calc(100vh - 230px)" :data="tableData" style="width: 100%;">
<!--      <el-table-column  align="center"-->
<!--                        type="index"-->
<!--                        width="55" label="#" >-->
<!--      </el-table-column>-->
      <el-table-column prop="name"  width="90" label="缩略图" >
        <template #default="scope">
          <div style="height: 100%;display: flex;align-items:center;padding: 5px 0">
            <el-image
                style="width: 60px; height: 60px"
                :src="scope.row.logo"
                :preview-src-list="[scope.row.logo]">
            </el-image>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="product_name" label="产品名称" >
      </el-table-column>
      <el-table-column prop="category_name" label="分类" >
      </el-table-column>
      <el-table-column prop="sequence" label="排序" >
      </el-table-column>
      <el-table-column prop="address" label="操作" width="110">
        <template #header>
          <el-button  @click="add()" type="primary" style="margin:10px 0;">新增</el-button>
        </template>
        <template #default="scope">
          <div style="margin: 5px 0">
            <el-button-group>
<!--              <el-button size="mini" type="primary" icon="el-icon-edit" @click="upData(scope.row)"></el-button>-->
              <el-button size="mini" type="primary" icon="el-icon-delete" @click="deleData(scope.row)"></el-button>
            </el-button-group>
          </div>

        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 20px">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 20, 30, 40,50]"
          :page-size="limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
      >
      </el-pagination>

    </div>
    <el-drawer
        title="新增/修改"
        v-model="drawer"
        size="50%"
        direction="rtl"
        :close-on-click-modal="false"
        destroy-on-close>
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm">
        <el-form-item label="前台分类" prop="front_category_id">
          <el-cascader
              placeholder=""
              disabled
              ref="cascaderHandle"
              style="width: 100%"
              :options="fatherArr"
              v-model="ruleForm.front_category_id"
              :props="{
                          label: 'name',
                          value: 'id',
                          children: 'children',
                          checkStrictly: true
                          }"
              :filterable="true">
          </el-cascader>
        </el-form-item>
        <div style="color: #606266;display: flex;justify-content: space-between"><span>● 指定商品</span><el-button size="medium" type="text" @click="addProduct()"> 添加指定商品</el-button></div>
        <el-table  max-height="300px" :data="ruleForm.products" style="width: 100%;margin:10px 0 30px 0">
          <el-table-column  type="index" label="#" width="60">
          </el-table-column>
          <el-table-column prop="name" label="产品名称" min-width="110">
          </el-table-column>
          <el-table-column prop="name" label="排序" min-width="110">
            <template #default="scope">
              <el-input-number size="mini" :min="0" v-model="scope.row.sequence"></el-input-number>
            </template>
          </el-table-column>
          <el-table-column fixed="right" prop="unit" label="操作" width="57">
            <template #default="scope">
              <el-button size="medium" type="text" @click="deletCou(scope.row,scope.$index)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-form-item>
          <el-button type="primary" v-if="ruleForm.id" :loading="login" @click="okAdd('ruleForm',2)">修改</el-button>
          <el-button type="primary" v-else :loading="login" @click="okAdd('ruleForm',1)">保存</el-button>
        </el-form-item>
      </el-form>
      <el-drawer
          title="新增指定商品"
          size="40%"
          :append-to-body="true"
          v-model="LabeDrawerCou" >
        <el-scrollbar style="height: 90%" height="600px"  >
          <div>
            <el-input size="mini" style="width: 300px;margin: 0 10px 0 0"  v-model="Coukeywords"></el-input>
            <el-button size="mini" @click="Cousearch()" type="primary" style="margin:10px 0;" icon="el-icon-search"></el-button>
          </div>
          <span>地区：{{addData}}</span>
          <el-table @select="handChange"  max-height="450px" :data="CoukListarr" style="width: 100%;margin:10px 0 30px 0">
            <el-table-column
                type="selection"
                width="50">
            </el-table-column>
            <el-table-column prop="name"  width="55" label="logo" >
              <template #default="scope">
                <div style="height: 100%;display: flex;align-items:center;padding: 5px 0">
                  <el-image
                      style="width: 30px; height: 30px"
                      :src="scope.row.logo"
                      :preview-src-list="[scope.row.logo]">
                  </el-image>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="商品名称" min-width="120">
            </el-table-column>
            <el-table-column prop="category_name" label="商品类型" min-width="80">
            </el-table-column>

          </el-table>
          <el-pagination
              small
              background
              :pager-count="5"
              @size-change="CouSizeChange"
              @current-change="CouCurrentChange"
              :current-page="Coupage"
              :page-sizes="[10, 20, 30, 40,50]"
              :page-size="Coulimit"
              layout="total, sizes, prev, pager, next, jumper"
              :total="Coutotal">

          </el-pagination>
        </el-scrollbar>
        <el-button type="primary" @click="okaddCou()">确定</el-button>
      </el-drawer>
    </el-drawer>
  </div>
</template>
<script>
// @ is an alias to /src
import * as axios from "@/api/bacclassChildApi"
import {postProduct} from "@/api/common";
import * as http from "@/api/Api.js"

//本页面接口
export default {
  name: 'brand',
  props:['nowData','fatherArr','area_id'],
  data(){
    return{
      login:false,
      drawer:false,
      tableData:[],
      page:1,
      limit:10,
      total:0,
      keywords:'',
      ruleForm:{
        products:[],
        sequence:50,
        front_category_id:this.nowData.front_category_id
      },
      /*商品--*/
      CoukListarr:[],//指定商品组
      CoukList:[],//指定商品
      nowCoukList:[],//选中指定商品
      LabeDrawerCou:false,//标签
      Coupage:1,
      Coulimit:10,
      Coutotal:0,
      Coukeywords:'',
      /*商品--*/
      rules:{
        front_category_id: [
          { required: true, message: '请选择分类', trigger: 'blue' },
        ],
      },
      addData:''
    }
  },
  created() {
    this.onTable()
    this.keyupSubmit()
    this.getAddname()

  },

  methods:{
    keyupSubmit(){
      document.onkeydown=e=>{
        let _key=window.event.keyCode;
        if(_key===13){
          this.search()
        }
      }
    },
    onTable(){
      let form={
        page:this.page,
        limit:this.limit,
        keywords:this.keywords,
        front_category_id:this.nowData.front_category_id,
      }
      axios.getrecomData(form).then((res)=>{
        console.log(res)
        this.tableData=res.data.data
        console.log('this.tableData',this.tableData)
        this.total=res.data.total
      }).catch((err)=>{

      })
    },
    /*新增产品*/
    Cousearch(){
      this.Coupage=1
      this.onProductdata()
    },
    onProductdata(){
      let data={
        page:this.Coupage,
        limit:this.Coulimit,
        keywords:this.Coukeywords,
        area_id:this.area_id
      }
      postProduct(data).then((res)=>{
        if(res.code==200){
          this.CoukListarr=res.data.data
          this.Coutotal=res.data.total

        }
      }).catch(()=>{

      })
    },
    addProduct(){
      this.onProductdata()
      this.LabeDrawerCou=true
    },
    CouSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.Coulimit=val
      this.onProductdata()
    },
    CouCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.Coupage=val
      this.onProductdata()
    },
    handChange(selection,row){
      var newNum1 = this.ruleForm.products.find((item, index) => {
        return item.product_id == row.id;
      })
      if(newNum1){
        console.log(newNum1)
      }else {
        this.ruleForm.products.push({product_id:row.id,name:row.name,sequence:50,})
      }
    },
    okaddCou(){
      this.LabeDrawerCou=false
    },
    deletCou(row,i){
      this.ruleForm.products.splice(i,1)
    },
    /*新增产品*/
    search(){
      this.page=1
      this.onTable()
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.limit=val
      this.onTable()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.page=val
      this.onTable()
    },
    add(){
      this.ruleForm={
        products:[],
        front_category_id:this.nowData.front_category_id
      }
      this.drawer=true
    },
    upData(row){
      this.ruleForm.id=row.id
      this.ruleForm.front_category_id=row.front_category_id
      this.ruleForm.sequence=row.sequence
      if(row.products==''){
        this.ruleForm.products=[]
      }else {
        this.ruleForm.products=row.products
      }
      this.drawer=true
    },
    okAdd(formName,val){
      if(this.login){
        return false
      }
      this.login=true
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(val==1){
            axios.postaddrecom(this.ruleForm).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                setTimeout(function () {
                  that.drawer=false
                  that.login=false
                },1000)
              }else {
                this.login=false
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }else {
            axios.posteditrecom(this.ruleForm).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                this.login=false
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.login=false
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }


        } else {
          that.drawer=false
          console.log('error submit!!')
          return false
        }
      })
    },
    deleData(row){
      this.$confirm('删除!!, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
          .then(() => {
            axios.postdelrecom({id:row.id}).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.$message.error('服务器报错！！请联系管理员')
            })
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: '已取消删除',
            })
          })
    },
  getAddname(){
      http.post({area_id:this.area_id},"/api/admin/area/view").then(res => {
        this.addData = res.data;
      })
    }
  }
}
</script>
<style lang="less" >
.avatar-uploader .el-upload {
  border: 1px dashed #c1c1c1;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.el-upload{

}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-table .el-table__cell{
  padding: 0;
}
</style>