<template>
  <div class="about">
                <el-select clearable style="margin:0 15px" v-model="area_id" @clear="clearOption" filterable remote>
                <el-option
                    v-for="item in region"
                    :key="item.id"
                    :label="item.area_name"
                    :value="item.area_id"

                    >
                </el-option>
              </el-select>
      <el-button @click="search" type="primary" style="margin:10px 0;" icon="el-icon-search"></el-button>

    <el-table
        :data="tableData"
        size="medium"
        height="calc(100vh - 130px)"
        row-key="id"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
      <el-table-column
          prop="name"
          label="分类名称">
      </el-table-column>
      <el-table-column show-overflow-tooltip prop="area_name" min-width="157" label="地区" >
      </el-table-column>
      <el-table-column
          width="60"
          prop="is_stop"
          label="启用">
        <template #default="scope">
            <el-tag size="mini" v-if="scope.row.is_stop==0" effect="dark" >启用</el-tag>
            <el-tag size="mini" v-if="scope.row.is_stop==1" type="info" >停用</el-tag>
        </template>
      </el-table-column>
      <el-table-column
          width="60"
          prop="sequence"
          label="排序">
      </el-table-column>
      <el-table-column
          width="60"
          prop="id"
          label="ID">
      </el-table-column>
      <el-table-column
          prop="text"
          width="310"
          label="操作">
        <template #header>
          <el-button @click="copy()" type="primary" size="mini" style="margin:10px 10px;" icon="el-icon-document-copy">复制</el-button>
          <el-button  @click="add()" size="mini" type="primary" style="margin:10px 0;">新增</el-button>
        </template>
        <template #default="scope">
          <div style="margin: 5px 0">
            <el-button-group>
              <el-button size="mini" type="primary" icon="el-icon-circle-plus-outline" @click="add(scope.row)"></el-button>
              <el-button size="mini"  type="primary"  @click="addfat(scope.row,)">推荐产品</el-button>
              <el-button size="mini" type="primary"  @click="addbd(scope.row,)">产品绑定</el-button>
<!--              <el-button size="mini" :disabled="scope.row.level==1||scope.row.level==2" type="primary"  @click="addbd(scope.row,)">产品绑定</el-button>-->
              <el-button size="mini" type="primary" icon="el-icon-edit" @click="upData(scope.row)"></el-button>
              <el-button size="mini"  type="primary" icon="el-icon-delete" @click="deleData(scope.row)"></el-button>
            </el-button-group>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-drawer
        title="分类新增/修改"
        v-model="drawer"
        size="30%"
        direction="rtl"
        :close-on-click-modal="false"
        destroy-on-close>
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm">
        <el-form-item  label="图标" prop="">
          <el-upload
              style=" width: 100px;height: 100px"
              class="avatar-uploader"
              :action="UpURL"
              :show-file-list="false"
              :http-request="httpRequest"
              :before-upload="beforeAvatarUpload"
          >
            <img v-if="ruleForm.icon" :src="ruleForm.icon" class="avatar" />
            <i v-else  class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <p style="color: #999999"><span style="font-size: 16px" class="el-icon-info"> </span>图片尺寸 宽 200 X 高 200</p>
        </el-form-item>
        <el-form-item label="分类名称" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="上级分类" prop="father_id">
          <el-cascader
              placeholder=""
              ref="cascaderHandle"
              style="width: 100%"
              :options="fatherArr"
              v-model="ruleForm.father_id"
              :props="{
                          label: 'name',
                          value: 'id',
                          children: 'children',
                          checkStrictly: true
                          }"
              :filterable="true">
          </el-cascader>
        </el-form-item>

        <el-form-item label="地区" prop="area_id">
                <el-select clearable  style="width:100%" v-model="ruleForm.area_id" >
                <el-option
                    v-for="item in region"
                    :key="item.id"
                    :label="item.area_name"
                    :value="item.area_id">
                </el-option>
              </el-select>
            </el-form-item>

        <el-form-item label="后台分类" prop="">
          <el-cascader
              placeholder=""
              ref="cascaderHandle"
              style="width: 100%"
              :options="categoryArr"
              v-model="ruleForm.category_id"
              :props="{
                          label: 'name',
                          value: 'id',
                          children: 'children',
                          checkStrictly: true
                          }"
              :filterable="true">
          </el-cascader>
        </el-form-item>
        <el-form-item label="是否停用" prop="is_stop">
          <div style="display: flex;justify-content: space-between;align-items:center;height: 40px">
            <el-switch
                style="display: block"
                v-model="ruleForm.is_stop"
                active-text="是"
                inactive-text="否"
                :active-value="1"
                :inactive-value="0">
            </el-switch>
          </div>
        </el-form-item>
        <el-form-item label="排序" >
          <el-input-number style="width: 100%" :min="0" controls-position="right" v-model="ruleForm.sequence"></el-input-number>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" v-if="ruleForm.id" :loading="login" @click="okAdd('ruleForm',2)">修改</el-button>
          <el-button type="primary" v-else :loading="login" @click="okAdd('ruleForm',1)">保存</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>

    <el-drawer title="推荐产品"
               v-model="drawerCha"
               size="60%"
               direction="rtl"
               :close-on-click-modal="false"
               destroy-on-close>
      <recommend :nowData="nowData" :fatherArr="tableData" :area_id="area_id"></recommend>
    </el-drawer>
    
    <el-drawer title="产品绑定"
               v-model="drawerbd"
               size="60%"
               direction="rtl"
               :close-on-click-modal="false"
               destroy-on-close>
      <front :nowData="nowData" :fatherArr="tableData" :area_id="area_id"></front>
    </el-drawer>
  <el-drawer title="前台分类模块复制" v-model="c_drawereva">
      <div style="display: flex; flex-direction:column; justify-content: left; align-items: left;">
        <el-select size="mini" clearable style="width:80%;margin:0 15px" v-model="copy_area_id" @clear="clearOption" filterable remote >
                <el-option
                    v-for="item in region_copy_list"
                    :key="item.id"
                    :label="item.area_name"
                    :value="item.area_id"
                    >
                </el-option>
              </el-select>
              <el-button :loading="loading" size="mini" @click="ok_copy" type="primary" style="margin:20px 0 0 15px;width:80%;" icon="el-icon-document-checked">确认复制</el-button>
      </div>
    </el-drawer> 
  </div>
</template>
<script>
// @ is an alias to /src
import {postCateTree, getCharge, getUQIMGurl, getIMG} from "@/api/common";
import {addDq,get} from "@/api/Api.js"
import * as axios from "@/api/backclassApi"//本页面接口
import recommend from '../components/backclassChild/recommend'
import front from '../components/backclassChild/front'
export default {
  name: 'fatherArr',
  components:{
    recommend,
    front,
  },
  data(){
    return{
      UpURL:'',
      login:false,
      drawer:false,
      drawerCha:false,
      drawerbd:false,
      area_id:'',
      category:'',
      nowData:{
        front_category_id:'',
      },
      tableData:[],
      fatherArr:[],
      categoryArr:[],
      ruleForm:{
        name:'',
        category_id:[],
        father_id:[],
        icon:'',
        is_stop:0,
        sequence:50,
        area_id:''
      },
      rules:{
        name: [
          { required: true, message: '请填写', trigger: 'blue' },
        ],
        father_id: [
          { required: true, message: '请选择', trigger: 'blue' },
        ],
        category_id: [
          { required: true, message: '请选择', trigger: 'blue' },
        ],
        is_stop: [
          { required: true, message: '请选择', trigger: 'blue' },
        ],
         area_id: [
          { required: true, message: '请选择', trigger: 'blue' },
        ],
      },
      region:[],
      region_copy_list:[],
      copy_area_id:'',
      c_drawereva:false,
      loading:false
    }
  },
  created() {
    this.onArea()
    this.getaddDq()
  },
  methods:{
    //LOGO上传图片方法
    httpRequest(options) {
      this.login=true
      // console.log('options',options.file,options.file.type)
      return new Promise((resolve, reject) => {
        let src=options.file.type
        getUQIMGurl({bucket:'product',ext:'.'+src.substr(6)}).then((res)=>{
          // console.log('获取URL',res)
          if(res.code==0){
            this.UpURL=res.data.upload_url
            let url=res.data.preview_url
            // console.log(this.UpURL)
            getIMG(options.file,this.UpURL,options.file.type).then((res)=>{
              resolve(res)
              this.ruleForm.icon=url
              this.login=false
            }).catch((err)=>{
              reject(err)
              this.login=false
            })
          }else {
            this.$message.error('获取上传地址失败！！')
            this.login=false
          }
        })

      })
    },
    //
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg'||
          file.type === "image/png" ||
          file.type === "image/jpg"||
          file.type === "image/gif";
      // const isLt2M = file.size / 1024 / 1024 < 4
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG、JPEG、PNG、 格式!')
      }
      // if (!isLt2M) {
      // this.$message.error('上传头像图片大小不能超过 4MB!')
      // }
      return isJPG
      // && isLt2M
    },
    /*图片上传*/
    onArea(){
      postCateTree().then((res)=>{
        this.categoryArr=res.data
        this.categoryArr.unshift({
          name:'无分类',
          id:0
        })
      }).catch((err)=>{

      })
    },
    oncategory(){

    },
    onTable(){
        axios.getbackclassData(this.area_id).then((res)=>{
        console.log(res)
        this.tableData=res.data
        this.fatherArr=JSON.parse(JSON.stringify(res.data))
        this.fatherArr.unshift({
          name:'顶层分类',
          id:0
        })
        // console.log('this.tableData',this.tableData)
      }).catch((err)=>{
      })
    },
    addfat(row){
      // console.log(row)
      this.nowData.front_category_id=row.id
      this.drawerCha=true
    },
    addbd(row){
      // console.log(row)
      this.nowData.front_category_id=row.id
      this.drawerbd=true
    },
    add(row,val){
      this.ruleForm= {
        name:'',
        category_id:[],
        father_id:[],
        icon:'',
        is_stop:0,
        sequence:50,
      }
      if(row){
        this.ruleForm.father_id=row.id
      }
      this.drawer=true
    },
    upData(row){
      this.ruleForm.id=row.id
      this.ruleForm.name=row.name
      this.ruleForm.category_id=row.category_id
      this.ruleForm.father_id=row.father_id
      this.ruleForm.icon=row.icon
      this.ruleForm.is_stop=row.is_stop
      this.ruleForm.sequence=Number(row.sequence)
      this.ruleForm.area_id = row.area_id
      this.drawer=true
    },
    okAdd(formName,val){
      if(this.login){
        return false
      }
      // this.ruleForm.father_id=JSON.stringify(this.ruleForm.father_id)

      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.login=true
          if(val==1){
            axios.postaddbackclass(this.ruleForm).then((res)=>{
              if(res.code==200){
                this.onTable()
                this.$message.success(res.msg)
                this.onArea()
                let that=this
                setTimeout(function () {
                  that.drawer=false
                  that.login=false
                },1000)
              }else {
                this.login=false
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }else {
            axios.posteditbackclass(this.ruleForm).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                this.onArea()
                let that=this
                this.login=false
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.login=false
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    deleData(row){
      this.$confirm('删除!!, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
          .then(() => {
            axios.postdelbackclass({id:row.id}).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.$message.error('服务器报错！！请联系管理员')
            })
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: '已取消删除',
            })
          })
    },
    search(){
      this.onTable()
    },
    // 获取城市地区列表数据
    async getaddDq(){
      const {data:res} = await addDq()
      this.region = res
      this.region_copy_list = res
      this.area_id = this.region[0].area_id
      this.onTable(this.area_id)
    },
    clearOption(){
      this.area_id = this.region[0].area_id
      this.onTable(this.area_id)
    },
    copy(){
      this.c_drawereva = true
    },
    async ok_copy(){
      this.loading = true
      const res = await get({area_id:this.copy_area_id},"/api/admin/categoryCopy")
      if(res.code === 200){
        this.$message.success(res.msg)
        this.c_drawereva = false
        this.loading =false
      }
    }
  }
}
</script>
<style lang="less" >
.tab{
  margin: 5px 0 ;
  padding: 10px 20px;
  border: 1px solid #e8e8e8;
}
.avatar-uploader .el-upload {
  width: 98px!important;
  height: 98px!important;
  border: 1px dashed #c1c1c1;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.el-upload{
  width: 98px!important;
  height: 98px!important;
}
.el-checkbox{
  height: 32px;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 98px!important;
  height: 98px!important;
  line-height: 98px;
  text-align: center;
}
.avatar {
  width: 98px!important;
  height: 98px!important;
  display: block;
}
.el-table .el-table__cell{
  padding: 0;
}
.suo .el-card__body{
  padding: 0!important;
}
.meng:hover .meng_1{
  display: block!important;
}
</style>